import { useEffect, useState } from 'react';
import {
  WhatsAppBackground,
  WhatsAppInfo,
  WhatsAppInfoContent,
  WhatsAppInfoTitle,
  WhatsAppSection,
  WhatsappStep,
} from './styles';
import BlurredQrCode from './assets/qrcode_blur.png';
import ModalConnectWhatsapp from './ModalConnectWhatsapp/ModalConnectWhatsapp';

export function WhatsApp({ setLoad }) {
  const [empCode, setEmpCode] = useState(null);
  const [loadingImg, setLoadingImg] = useState(true);
  const [activeWhatsapp, setActiveWhatsapp] = useState(false);
  const [connectedWhatsapp, setConnectedWhatsapp] = useState(false);
  const [modalConnect, setModalConnect] = useState(false);

  console.log(empCode);

  useEffect(() => {
    const empCode = sessionStorage.getItem('ASS_COD');
    setEmpCode(empCode.toLowerCase());
  }, []);

  return (
    <>
      {modalConnect && <ModalConnectWhatsapp setModal={setModalConnect} />}
      <WhatsAppBackground>
        <WhatsAppSection>
          <section>
            {loadingImg && (
              <img src={BlurredQrCode} style={{ width: '100%' }} />
            )}
            {empCode && (
              <img
                src={`https://endorvistoria.com.br:21463/v1/wpp/connect/aaaaaa`}
                alt=''
                style={{ display: loadingImg ? 'none' : 'block' }}
                onLoad={() => setLoadingImg(false)}
              />
            )}
          </section>
          <div>
            <div className='whatsapp_actions'>
              {!connectedWhatsapp ? (
                <div
                  className={`btn_action ${activeWhatsapp ? 'active' : 'not_active'}`}
                  onClick={() => {
                    if (!activeWhatsapp) {
                      setModalConnect(true);
                    } else {
                      return;
                    }
                  }}
                >
                  {activeWhatsapp ? 'Ativo' : 'Ativar'}
                </div>
              ) : (
                <div className='btn_action connected'>Conectado</div>
              )}
              {!connectedWhatsapp && activeWhatsapp && (
                <button className='btn_disconnect'>Desativar</button>
              )}
            </div>

            <h4>Conecte seu WhatsApp</h4>
            <p>Envie mensagens via Automação</p>
          </div>
        </WhatsAppSection>
        <WhatsAppInfo>
          <WhatsAppInfoTitle>
            <div className='saldo'>
              <h2 className='saldo_title'>5.000</h2>
              <span className='saldo_subtitle'>Saldo Atual</span>
            </div>
            <span className='btn_recarga'>
              Solicitar <br></br> Recarga
            </span>
          </WhatsAppInfoTitle>
          <WhatsAppInfoContent>
            <h2>Veja como Ativar:</h2>
            <WhatsappStep>
              <li>
                1. Clique no botão <strong>Ativar</strong> ao lado, mas,
                lembre-se, você precisará de <strong>saldo</strong> para
                utilizar o recurso;
              </li>
              <li>
                2. Leia com bastante atenção aos{' '}
                <strong>Termos, Políticas e Condições</strong> de uso;
              </li>
              <li>
                3. Se optar por ativar, marque a opção indicando estar ciente e
                de acordo com os Termos, Políticas e Condições de uso
                apresentadas;
              </li>
              <li>
                4. Por fim, clique em <strong>Confirmar</strong>! Você voltará
                para esta tela para vincular seu número de WhatsApp ao serviço!
              </li>
            </WhatsappStep>
          </WhatsAppInfoContent>
        </WhatsAppInfo>
      </WhatsAppBackground>
    </>
  );
}
