import styled from 'styled-components';

export const ModalWhatsapp = styled.div`
  background-color: #fff;
  width: 750px;
  height: 490px;
  border-radius: 15px;
  padding: 10px;
  position: relative;

  .close_icon {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
  }

  .modal_content {
    height: 100%;
    display: flex;
    flex-direction: row;

    .whatsapp_banner {
      width: 40%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 30px 0;

      .whatsapp_top,
      .whatsapp_bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .whatsapp_top {
        gap: 0.5rem;

        .top_title {
          font-size: 1.3rem;
          font-weight: 700;
        }

        .top_subtitle {
          font-size: 14px;
        }
      }
    }
    .terms_banner {
      /* background-color: lavender; */
      width: 60%;
      padding: 10px 10px 10px 0;

      .terms_container {
        background-color: #f5f5f7;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .terms {
          margin: 15px 15px 0 15px;

          h2,
          h3 {
            margin: 0;
          }

          p {
            margin: .7rem 0;
          }

          
        }

        .controller {
          margin: 15px;

          .checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.3rem;
            label {
              color: #000;
              font-size: 11px;
            }
            input {
              width: 0.75rem;
              height: 0.75rem;

              appearance: none;
              border-radius: 0.25rem;
              transition-duration: 0.3s;
              cursor: pointer;

              outline: solid 1px #00b065;
              border: 2px solid #fff;
              background-color: #fff;

              :checked {
                border: 2px solid #fff;
                background-color: #00b065;
              }

              :active {
                border: 2px solid #00b065;
              }

              :checked:focus {
                border: 2px solid #fff;
                outline: solid 1px #00b065;
              }

              :not(:checked):focus {
                outline: solid 1px #000;
              }

              :not(:checked) {
                outline: 1px solid #000;
              }
            }
          }

          .btn_accept {
            border: 0;
            background-color: #0049c5;
            width: 100%;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            color: #fff;
            font-weight: 700;
            margin-top: 10px;
            transition: .2s;

            :disabled {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
`;
