import React, { useState } from 'react';
import { BackgroundModal } from '../../../../../components/ModalCSAT/styles';
import { ModalWhatsapp } from './styles.js';
import WhatsAppLogo from '../assets/download.png';
import CloseIcon from '../assets/Group.btn.sair.svg';

function ModalConnectWhatsapp({ setModal }) {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  return (
    <BackgroundModal>
      <ModalWhatsapp>
        <div className='close_icon' onClick={() => setModal(false)}>
          <img src={CloseIcon} alt='' />
        </div>
        <div className='modal_content'>
          <div className='whatsapp_banner'>
            <div className='whatsapp_top'>
              <img src={WhatsAppLogo} />
              <span className='top_title'>WhatsApp</span>
              <span className='top_subtitle'>Termos e Condições</span>
            </div>
            <div className='whatsapp_bottom'>
              <strong>EPTA Teconolgia</strong>
              <span>www.eptatecnologia.com.br</span>
            </div>
          </div>
          <div className='terms_banner'>
            <div className='terms_container'>
              <div className='terms'>
                <h2>Termos & Condições</h2>
                <p>
                  Leia com bastante atenção aos termos e condições de uso abaixo
                  descritos e confirme quando estiver pronto para prosseguir!
                </p>

                <h3>Limite de Disparos</h3>
                <p style={{ fontSize: "12px" }}>
                  - Você possui um limite de disparos de mensagens conforme
                  modelo de contratação/saldo! Para mais detalhes sobre limites,
                  contate-nos;
                </p>
                <h3>Execução Imediata</h3>
                <p style={{ fontSize: "12px" }}>
                  - Ao conectar seu WhatsApp, a fila de disparos será executada
                  de imediato! Certifique-se que das automações estarem
                  devidamente configuradas;
                </p>
                <h3>Políticas da Meta</h3>
                <p style={{ fontSize: "12px" }}>
                  - Ao utilizar este serviço você declara estar ciente que,
                  infrigir as <u>Políticas da Meta</u>, pode acarretar em
                  restrições, suspensões e até banimento de sua conta!
                </p>
              </div>
              <div className='controller'>
                <div className='checkbox'>
                  <input
                    type='checkbox'
                    name='confirm'
                    id='confirm'
                    checked={acceptedTerms}
                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                  />
                  <label htmlFor='confirm'>
                    Confirmo que li e estou de acordo com todos os Termos e
                    Condições!
                  </label>
                </div>
                <button className='btn_accept' disabled={!acceptedTerms}>
                  ACEITAR E CONFIRMAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalWhatsapp>
    </BackgroundModal>
  );
}

export default ModalConnectWhatsapp;
