import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { KanbanWrapper } from './style/styledKanban'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import _ from 'lodash'
import moment from 'moment'
import Loading from '../../../../Loading/Loading'
import Vistoria from '../Data Table/Vistoria/Vistoria'
import ResumoTable from '../Data Table/ResumoTable/ResumoTable'
import { FaUserAlt, FaFilter } from 'react-icons/fa'
import { HiClipboard, HiClipboardCheck } from 'react-icons/hi'
// import { CadastroAssociado } from "../../../../../context/CadastroASSContext";
import { useNavigate } from 'react-router-dom'
import ModalConfirmarPerdida from './ModalConfirmarPerdida/ModalConfirmarPerdida'
// import { AuthContext } from "../../../../../context/auth";
import { AiFillFilePdf, AiOutlineShareAlt } from 'react-icons/ai'
import { BiWindowOpen, BiDotsVerticalRounded } from 'react-icons/bi'
import Dropdown from 'react-bootstrap/Dropdown'
import Endor from '../Data Table/assets/Endor'
import checarStatusOportunidade from '../../../../../utils/checarStatusOportunidade'
import {
  ButtonInsideWrapper,
  TableButton,
  TableALink,
} from '../Data Table/styles/styledDataTable'
// import enviarPDF from "../../../../../utils/enviarPDF";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { messageWpp } from '../messageWpp'
import { SwitchBotoes } from '../styles/styledOportunidades'
import DraggableItem from './DraggableItem'
import DroppableWrapper from './DroppableWrapper'
import { oportunidadeRepository } from '../../../../../api/repositories/oportunidadesRepository'
import { statusRepository } from '../../../../../api/repositories/statusRepository'
import groupDots from '../../../assets/group-dots.svg'
import iconPdf from '../../../assets/icon-view-pdf.svg'
import iconShare from '../../../assets/icon-share.svg'
import { CadastroAssociado } from '../../../../../context/CadastroASSContext'

function Kanban({ FUN_ID, filterStatus, selectedVendedores, pesquisa }) {
  // const { PER_ID } = useContext(AuthContext);

  const VEN_ID = sessionStorage.getItem('VEN_ID')
  const LOGIN = sessionStorage.getItem('LOGIN')
  const JWT_TOKEN = sessionStorage.getItem('JWT')
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  const [loading, setLoading] = useState(true)
  const [vistoria, setVistoria] = useState(false)
  const [dataResumo, setDataResumo] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [colunas, setColunas] = useState([])
  const [cards, setCards] = useState([])
  const [moveuCard, setMoveuCard] = useState(false)

  const [STATUS_FILTER, setSTATUS_FILTER] = useState('PENDENTE')

  const [modalConfirmaPerdida, setModalConfirmaPerdida] = useState(false)
  const [OPO_ID, setOPO_ID] = useState('')

  const navigate = useNavigate()

  const MySwal = withReactContent(Swal)

  // useEffect(() => {
  //   atualizarAposMoverCard(OPO_ID)
  // }, [moveuCard])

  console.log('MEU SELECTED VENDEDORES', selectedVendedores)

  const valuesVendors = selectedVendedores.map((item) => item.value)

  console.log(valuesVendors)
  console.log(colunas)

  useEffect(async () => {
    setLoading(true)
    try {
      const data = await oportunidadeRepository.listarOportunidadesKanban(
        VEN_ID,
        FUN_ID,
        filterStatus,
      )
      if (FUN_ID !== '') {
        setLoading(false)
      }
      setLoading(true)
      console.log('LISTA OPORTUNIDADES', data)
      const upperize = (obj) =>
        Object.keys(obj).reduce((acc, k) => {
          acc[k.toUpperCase()] = obj[k]
          return acc
        }, {})
      setColunas(upperize(data.col))
      var newCards = _.map(data.col, (i) => {
        return i.itens
      })
      setCards(newCards)
      setMoveuCard(false)
      setLoading(false)
    } catch (error) {
      console.error(error)
      if (FUN_ID !== '') {
        setLoading(false)
      }
    }

    console.log(colunas)

    /*
    axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/listar-oportunidades-kanban/${VEN_ID}/${FUN_ID}/${filterStatus}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
      )
      .then((res) => {
        if (FUN_ID !== '') {
          setLoading(false)
        }
        console.log(res)
        const upperize = (obj) =>
          Object.keys(obj).reduce((acc, k) => {
            acc[k.toUpperCase()] = obj[k]
            return acc
          }, {})
        // console.log(res.data.col["NUTRIÇÃO DE CONHECIMENTO"].itens.reverse());
        setColunas(upperize(res.data.col))
        var newCards = _.map(res.data.col, (i) => {
          return i.itens
        })
        setCards(newCards)
      })
      .catch((err) => {
        console.log(err)
        if (FUN_ID !== '') {
          setLoading(false)
        }
      })
    */
  }, [FUN_ID, filterStatus, moveuCard])

  function checarStatusVistoria() {
    const verificarStatus = async () => {
      try {
        await statusRepository.verificarStatus()
        setVistoria(true)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
        MySwal.fire({
          title: 'Atenção!',
          text: 'Serviço indisponível no momento. Por favor, tente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        }).then((res) => {
          if (res.isConfirmed) {
            navigate('/home/oportunidades')
          } else {
            // not clicked
          }
        })
      }
    }

    verificarStatus()

    /*
    axios
      .get(`https://kaminocrm.com.br:7778/status/verificarStatus/`, {
        headers: {
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        console.log(res)
        setVistoria(true)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        MySwal.fire({
          title: 'Atenção!',
          text: 'Serviço indisponível no momento. Por favor, tente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        }).then((res) => {
          console.log(res)
          if (res) {
            navigate('/home/oportunidades')
          } else {
            // not clicked
          }
        })
      })
    */
  }

  function onDragEnd({ destination, source }) {
    var cardSelecionado = colunas[source.droppableId].itens[source.index]
    setLoading(true)
    if (!destination) {
      setLoading(false)
      return
    }

    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      setLoading(false)
      return
    }

    async function dropCard() {
      // Copiar item da coluna antes de apagá-lo
      const itemCopia = {
        ...colunas[source.droppableId].itens[source.index],
      }
      setColunas((anterior) => {
        anterior = { ...anterior }
        anterior[source.droppableId].itens.splice(source.index, 1)
        anterior[destination.droppableId].itens.splice(
          destination.index,
          0,
          itemCopia,
        )
        return anterior
      })

      let valorAtualizadoSource = colunas[source.droppableId].itens
        .reduce((acumulado, objeto) => {
          const valor = objeto.VALOR_PLANO

          if (valor !== null && valor !== undefined) {
            return acumulado + valor
          } else {
            return acumulado
          }
        }, 0)
        .toLocaleString('pt-BR', {
          currency: 'BRL',
          style: 'currency',
          minimumFractionDigits: 2,
        })

      let valorAtualizadoDestination = colunas[destination.droppableId].itens
        .reduce((acumulado, objeto) => {
          const valor = objeto.VALOR_PLANO

          if (valor !== null && valor !== undefined) {
            return acumulado + valor
          } else {
            return acumulado
          }
        }, 0)
        .toLocaleString('pt-BR', {
          currency: 'BRL',
          style: 'currency',
          minimumFractionDigits: 2,
        })

      setColunas((prev) => {
        prev = { ...prev }
        prev[source.droppableId].valorplanos = valorAtualizadoSource
        prev[destination.droppableId].valorplanos = valorAtualizadoDestination
        return prev
      })
    }

    const destinationDroppableId = destination.droppableId
    const sourceDroppableId = source.droppableId
    const OPO_ID = cardSelecionado.OPO_ID

    console.log('INICIALIZADO', OPO_ID)

    const atualizarOportunidadeStatus = async () => {
      try {
        const response =
          await oportunidadeRepository.atualizarOportunidadeStatus(
            destinationDroppableId,
            sourceDroppableId,
            FUN_ID,
            OPO_ID,
          )
        console.log('OPORTUNIDADE ATUALIZADA COM SUCESSO', response)
        atualizarAposMoverCard(OPO_ID)
        await dropCard()
        console.log('atualizar oportunidade status', OPO_ID)

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    atualizarOportunidadeStatus()

    const atualizarAposMoverCard = async (OPO_ID) => {
      try {
        const data = await oportunidadeRepository.listarOportunidadesKanban(
          VEN_ID,
          FUN_ID,
          filterStatus,
        )
        if (FUN_ID !== '') {
          setLoading(false)
        }
        setLoading(true)
        console.log('LISTA OPORTUNIDADES', data)
        const upperize = (obj) =>
          Object.keys(obj).reduce((acc, k) => {
            acc[k.toUpperCase()] = obj[k]
            return acc
          }, {})
        setColunas(upperize(data.col))
        var newCards = _.map(data.col, (i) => {
          return i.itens
        })
        setCards(newCards)
        setLoading(false)
      } catch (error) {
        console.error(error)
        if (FUN_ID !== '') {
          setLoading(false)
        }
      }
    }

    /*
    axios
      .post(
        'https://kaminocrm.com.br:7778/oportunidades/atualizarOportunidadeStatus/',
        {
          destination: destination.droppableId,
          source: source.droppableId,
          fun_id: FUN_ID,
          opo_id: cardSelecionado.OPO_ID,
        },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
      )
      .then((res) => {
        dropCard()
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
    */
  }

  function mask(v) {
    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{2})(\d)/g, '($1) $2')
    v = v.replace(/(\d)(\d{4})$/, '$1-$2')
    return v.replace(/\s/g, '')
  }

  const filtrarItens = (itens) => {
    if (!pesquisa) {
      return itens
    }
    return itens.filter((item) =>
      item.CLI_NOME.toLowerCase().includes(pesquisa.toLowerCase()),
    )
  }

  // const colunasFiltradas = _.map(colunas, (data, key) => {
  //   const itensFiltrados = filtrarItens(data.itens)

  //   return {
  //     ...data,
  //     itens: itensFiltrados,
  //   }
  // })

  const OptionsButton = ({ el }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          id={'options'}
          // @ts-ignore
          variant="borderless-dark"
          bsPrefix="no-chevron"
          className="options-btn"
          size="sm"
          disabled={openModal ? true : false}
        >
          <img src={groupDots} alt="" />
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ willChange: 'transform' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.9375rem',
            }}
          >
            <TableALink
              className={`dropdown-item ${
                checarStatusOportunidade(el.OPO_STATUS) ||
                (el.PLANOS == null && el.VEI_ID == null)
                  ? 'not-allowed'
                  : ''
              }`}
              disabled={
                checarStatusOportunidade(el.OPO_STATUS) ||
                (el.PLANOS == null && el.VEI_ID == null)
              }
              target="_blank"
              href={`https://kaminocrm.com.br:9898/${ASS_COD}/${el.OPO_ID}`}
              style={{
                pointerEvents: `${
                  checarStatusOportunidade(el.OPO_STATUS) ||
                  (el.PLANOS == null && el.VEI_ID == null)
                    ? 'none'
                    : ''
                }`,
                opacity: `${
                  checarStatusOportunidade(el.OPO_STATUS) ||
                  (el.PLANOS == null && el.VEI_ID == null)
                    ? '0.6'
                    : '1'
                }`,
              }}
            >
              <ButtonInsideWrapper>
                <img src={iconPdf} alt="" />
                <span>Visualizar PDF</span>
              </ButtonInsideWrapper>
            </TableALink>

            <TableButton
              className="dropdown-item"
              disabled={
                checarStatusOportunidade(el.OPO_STATUS) ||
                (el.PLANOS == null && el.VEI_ID == null)
              }
              onClick={() => {
                window.scrollTo(0, 0)
                setLoading(true)
                setTimeout(function () {
                  window.open(
                    `https://wa.me/+55${el.CLI_TELEFONE}?text=${messageWpp(
                      el.EMP_ASSOCIACAO,
                      el.VEN_NOME,
                      ASS_COD,
                      el.OPO_ID,
                    )}`,
                    'response',
                    'resizable=yes',
                  )
                  setLoading(false)
                }, 1000)
              }}
            >
              <ButtonInsideWrapper>
                <img src={iconShare} alt="" />
                <span>Compartilhar Link</span>
              </ButtonInsideWrapper>
            </TableButton>

            {/* <TableButton
            className="dropdown-item"
            disabled={
              checarStatusOportunidade(el.OPO_STATUS) || el.OPO_STATUS == "VISTORIAS"
                ? true
                : false
            }
            onClick={() => {
              checarStatusVistoria();
              setLoading(true);
              setDataResumo(OPO_ID);
              }}
              >
          <ButtonInsideWrapper>
              <Endor size={17} /> <span>Gerar Vistoria</span>
              </ButtonInsideWrapper>
              </TableButton> */}

            {/* <TableButton
              className="dropdown-item"
              onClick={() => {
                setOpenModal(true)
                setDataResumo(el.OPO_ID)
                window.scrollTo(0, 0)
              }}
            >
              <ButtonInsideWrapper>
                <BiWindowOpen size={16} />{' '}
                <span style={{ fontSize: '14px' }}>Resumo da oportunidade</span>
              </ButtonInsideWrapper>
            </TableButton> */}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <>
      {modalConfirmaPerdida && (
        <ModalConfirmarPerdida
          closeModal={setModalConfirmaPerdida}
          OPO_ID={OPO_ID}
          OPO_STATUS={'PERDIDA'}
        />
      )}

      {loading ? <Loading /> : null}
      {vistoria && <Vistoria isVisible={setVistoria} OPO_ID={dataResumo} />}

      {openModal && (
        <ResumoTable closeModal={setOpenModal} OPO_ID={dataResumo} />
      )}

      <KanbanWrapper>
        <div className="container">
          <div className="wrapper">
            <DragDropContext onDragEnd={onDragEnd}>
              {_.map(colunas, (data, key) => {
                if (!data || !data.itens) return null // Proteção contra dados inválidos

                const dataFiltrado = {
                  ...data,
                  itens: filtrarItens(data.itens), // Filtrar os itens diretamente aqui
                }

                return (
                  <DroppableWrapper
                    data={dataFiltrado} // Passar os dados já filtrados
                    keyDrop={key}
                    selectedVendedores={valuesVendors}
                    setColunas={setColunas}
                    OptionsButton={OptionsButton}
                    setOpenModal={setOpenModal}
                    setDataResumo={setDataResumo}
                    mask={mask}
                  />
                )
              })}{' '}
              <div id="sentinela" />
            </DragDropContext>
          </div>
        </div>
      </KanbanWrapper>
    </>
  )
}

export default Kanban
